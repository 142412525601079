import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-1" }
const _hoisted_2 = { class: "col col-xs-6 col-lg-8 overflow-hidden" }
const _hoisted_3 = { class: "ellipsis title" }
const _hoisted_4 = {
  key: 0,
  class: "color-secondary"
}
const _hoisted_5 = {
  key: 1,
  class: "color-secondary"
}
const _hoisted_6 = {
  key: 2,
  class: "color-secondary"
}
const _hoisted_7 = {
  key: 3,
  class: "color-secondary"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "text-muted ellipsis color-secondary col col-xs-5 col-lg-3 text-right" }
const _hoisted_12 = { class: "d-flex justify-content-end flex-column align-items-end" }
const _hoisted_13 = { class: "mb-auto" }
const _hoisted_14 = {
  key: 0,
  style: {"color":"red"}
}
const _hoisted_15 = {
  key: 1,
  style: {"color":"black"}
}
const _hoisted_16 = { key: 2 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[10] || (_cache[10] = $event => ($options.pushRoute($props.link))),
    onMouseenter: _cache[11] || (_cache[11] = $event => ($data.hover = true)),
    onMouseleave: _cache[12] || (_cache[12] = $event => (($data.hover = false), ($data.detailsOpen = false))),
    class: "quick-list-item align-items-start"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass(["indicator-pill no-margin", {
          red: $props.status === 'No Work Order',
          blue: $props.status === 'Partially Delivered',
          gray: $props.status === 'Fully Delivered',
          green: $props.status === 'In Warehouse',
          yellow: $props.status === 'To Produce',
          grey: $props.status === 'Unknown',
        }])
      }, null, 2 /* CLASS */)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("b", null, _toDisplayString($props.qty), 1 /* TEXT */),
        _createTextVNode(" - " + _toDisplayString($props.item_name), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        ($props.fg_item)
          ? (_openBlock(), _createElementBlock("small", _hoisted_4, [
              _createElementVNode("a", {
                class: "underline-hover",
                onClick: [
                  _cache[0] || (_cache[0] = $event => ($options.pushRoute('/app/item/' + $props.fg_item))),
                  _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                ]
              }, _toDisplayString($props.fg_item), 1 /* TEXT */)
            ]))
          : ($props.customer && $props.item_code)
            ? (_openBlock(), _createElementBlock("small", _hoisted_5, [
                _createTextVNode(_toDisplayString($props.customer) + " - ", 1 /* TEXT */),
                _createElementVNode("a", {
                  class: "underline-hover",
                  onClick: [
                    _cache[2] || (_cache[2] = $event => ($options.pushRoute('/app/item/' + $props.item_code))),
                    _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                  ]
                }, _toDisplayString($props.item_code), 1 /* TEXT */)
              ]))
            : ($props.customer)
              ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString($props.customer), 1 /* TEXT */))
              : ($props.item_code)
                ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString($props.item_code), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("small", null, [
          _createElementVNode("span", null, [
            _createElementVNode("a", {
              class: "underline-hover",
              onClick: [
                _cache[4] || (_cache[4] = $event => ($options.pushRoute('/app/sales-order/' + $props.reference))),
                _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
              ]
            }, _toDisplayString($props.reference), 1 /* TEXT */)
          ]),
          ($props.direct_wo)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                _createTextVNode(" - "),
                _createElementVNode("a", {
                  class: "underline-hover",
                  onClick: [
                    _cache[6] || (_cache[6] = $event => ($options.pushRoute('/app/work-order/' + $props.direct_wo))),
                    _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
                  ]
                }, _toDisplayString($props.direct_wo), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          ($props.direct_po && $props.direct_wo)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, "-"))
            : _createCommentVNode("v-if", true),
          ($props.direct_po)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                _createElementVNode("a", {
                  class: "underline-hover",
                  onClick: [
                    _cache[8] || (_cache[8] = $event => ($options.pushRoute('/app/purchase-order/' + $props.direct_po))),
                    _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
                  ]
                }, _toDisplayString($props.direct_po), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _createCommentVNode(" v-if=\"hover\" "),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          ($props.due_in < 0)
            ? (_openBlock(), _createElementBlock("b", _hoisted_14, _toDisplayString($props.date), 1 /* TEXT */))
            : ($props.due_in === 0)
              ? (_openBlock(), _createElementBlock("b", _hoisted_15, _toDisplayString($props.date), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString($props.date), 1 /* TEXT */))
        ]),
        _createCommentVNode(" <div @click=\"getItemDetails(item_code)\" v-if=\"!detailsOpen\" v-on:click.stop>\n          Details\n        </div> ")
      ])
    ]),
    _createCommentVNode(" <div v-if=\"detailsOpen\" :style=\"{ height: '500px' }\"></div> ")
  ], 32 /* NEED_HYDRATION */))
}