<template>
  <div class="form-tabs-list">
    <ul class="nav form-tabs" id="form-tabs" role="tablist">
      <li class="nav-item show">
        <a
          class="nav-link"
          :class="{ active: currentTab === 0 }"
          role="tab"
          @click="changeTab(0)"
        >
          Production Overview
        </a>
      </li>
      <li class="nav-item show">
        <a
          class="nav-link"
          :class="{ active: currentTab === 1 }"
          role="tab"
          @click="changeTab(1)"
        >
          Purchase Overview
        </a>
      </li>
    </ul>
    <div class="layout-main-section">
      <div
        class="widget quick-list-widget-box"
        style="height: auto"
        v-if="currentTab === 0"
      >
        <div class="widget-head">
          <div class="widget-label">
            <div class="widget-title">
              <span class="ellipsis" title="Sales Order"
                >Delivery Overview</span
              >
            </div>
          </div>
          <div class="widget-control"></div>
        </div>
        <div class="widget-body">
          <manufacturing-overview-row
            v-for="so in salesorderData"
            :key="so.name"
            v-bind:qty="so.qty"
            v-bind:item_name="so.item_name"
            v-bind:item_code="so.item_code"
            v-bind:customer="so.customer"
            v-bind:date="so.date"
            v-bind:status="so.status"
            v-bind:link="so.link"
            v-bind:reference="so.parent"
            v-bind:due_in="so.due_in"
            v-bind:direct_wo="so.direct_wo"
            v-bind:direct_po="so.direct_po"
            v-bind:rowtype="so"
          >
          </manufacturing-overview-row>
        </div>
      </div>

      <div
        class="widget quick-list-widget-box"
        style="height: auto"
        v-if="currentTab === 1"
      >
        <div class="widget-head">
          <div class="widget-label">
            <div class="widget-title">
              <span class="ellipsis" title="Sales Order"
                >Purchase Overview</span
              >
            </div>
          </div>
          <div class="widget-control"></div>
        </div>
        <div class="widget-body">
          <manufacturing-overview-row
            v-for="sc in purchaseOrderData"
            :key="sc.name"
            v-bind:qty="sc.qty"
            v-bind:item_name="sc.fg_item_name"
            v-bind:item_code="sc.fg_item"
            v-bind:fg_item="sc.fg_item"
            v-bind:customer="sc.customer"
            v-bind:date="sc.date"
            v-bind:status="sc.status"
            v-bind:direct_po="sc.direct_po"
            v-bind:link="sc.link"
            v-bind:rowtype="po"
          >
          </manufacturing-overview-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManufacturingOverviewRow from "./manufacturing_overview_row.vue";

export default {
  components: {
    ManufacturingOverviewRow,
  },
  data() {
    return {
      currentTab: 0,
      salesorderData: [
        {
          customer: "",
          date: "",
          link: "",
          name: "",
          item_name: "Loading...",
          item_code: "",
          qty: "",
          sales_order: "",
          status: "Unknown",
          due_in: 0,
          direct_wo: "",
          direct_po: "",
        },
      ],
      purchaseOrderData: [
        {
          name: "",
          customer: "",
          link: "",
          item_name: "Loading...",
          item_code: "",
          qty: "",
          date: "",
          fg_item: "",
          status: "Unknown",
          direct_po: "",
        },
      ],
      timer: "",
      origin: window.location.origin,
      userPermissions: {},
    };
  },
  created() {
    this.fetchEventsList();
    this.timer = setInterval(this.fetchEventsList, 30000);
  },
  methods: {
    changeTab(id) {
      let self = this;
      self.currentTab = id;
    },
    fetchEventsList() {
      let self = this;
      frappe.call({
        method:
          "manufacturing_overview.manufacturing_overview.api.getSalesorderOverviewList",
        async: true,
        args: {},
        callback: function (r) {
          if (r.message) {
            self.salesorderData = r.message;
          }
        },
      });

      frappe.call({
        method:
          "manufacturing_overview.manufacturing_overview.api.getPurchaseOrderOverviewList",
        async: true,
        args: {},
        callback: function (r) {
          if (r.message) {
            self.purchaseOrderData = r.message;
            console.log(r.message);
          }
        },
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style></style>
