<template>
  <div
    @click="pushRoute(link)"
    @mouseenter="hover = true"
    @mouseleave="(hover = false), (detailsOpen = false)"
    class="quick-list-item align-items-start"
  >
    <div class="col-1">
      <span
        class="indicator-pill no-margin"
        v-bind:class="{
          red: status === 'No Work Order',
          blue: status === 'Partially Delivered',
          gray: status === 'Fully Delivered',
          green: status === 'In Warehouse',
          yellow: status === 'To Produce',
          grey: status === 'Unknown',
        }"
      ></span>
    </div>
    <div class="col col-xs-6 col-lg-8 overflow-hidden">
      <span class="ellipsis title"
        ><b>{{ qty }}</b> - {{ item_name }}</span
      >
      <div>
        <small v-if="fg_item" class="color-secondary">
          <a
            class="underline-hover"
            @click="pushRoute('/app/item/' + fg_item)"
            v-on:click.stop
            >{{ fg_item }}</a
          >
        </small>
        <small v-else-if="customer && item_code" class="color-secondary"
          >{{ customer }} -
          <a
            class="underline-hover"
            @click="pushRoute('/app/item/' + item_code)"
            v-on:click.stop
            >{{ item_code }}</a
          >
        </small>
        <small v-else-if="customer" class="color-secondary">{{
          customer
        }}</small>
        <small v-else-if="item_code" class="color-secondary">{{
          item_code
        }}</small>
      </div>
      <div>
        <small>
          <span>
            <a
              class="underline-hover"
              @click="pushRoute('/app/sales-order/' + reference)"
              v-on:click.stop
              >{{ reference }}</a
            >
          </span>
          <span v-if="direct_wo">
            -
            <a
              class="underline-hover"
              @click="pushRoute('/app/work-order/' + direct_wo)"
              v-on:click.stop
              >{{ direct_wo }}</a
            >
          </span>
          <span v-if="direct_po && direct_wo">-</span>
          <span v-if="direct_po">
            <a
              class="underline-hover"
              @click="pushRoute('/app/purchase-order/' + direct_po)"
              v-on:click.stop
              >{{ direct_po }}</a
            >
          </span>
        </small>
      </div>
    </div>

    <!-- v-if="hover" -->

    <div
      class="text-muted ellipsis color-secondary col col-xs-5 col-lg-3 text-right"
    >
      <div class="d-flex justify-content-end flex-column align-items-end">
        <div class="mb-auto">
          <b v-if="due_in < 0" style="color: red">{{ date }}</b>
          <b v-else-if="due_in === 0" style="color: black">{{ date }}</b>
          <span v-else>{{ date }}</span>
        </div>

        <!-- <div @click="getItemDetails(item_code)" v-if="!detailsOpen" v-on:click.stop>
          Details
        </div> -->
      </div>
    </div>
    <!-- <div v-if="detailsOpen" :style="{ height: '500px' }"></div> -->
  </div>
</template>

<script>
export default {
  name: "ManufacturingOverviewRow",
  data() {
    return {
      hover: false,
      detailsOpen: false,
      currentDetails: ["1", "2"],
    };
  },
  props: [
    "qty",
    "item_name",
    "item_code",
    "customer",
    "customershort",
    "date",
    "status",
    "link",
    "reference",
    "due_in",
    "direct_wo",
    "direct_po",
    "fg_item",
  ],
  methods: {
    pushRoute(link) {
      frappe.router.push_state(link);
    },
    // getItemDetails(item) {
    //   this.detailsOpen = !this.detailsOpen;
    //   frappe.call({
    //     method:
    //       "manufacturing_overview.manufacturing_overview.api.get_bom_tree",
    //     async: true,
    //     args: { item_code: item },
    //     callback: function (r) {
    //       console.log(r.message);
    //     },
    //   });
    // },
  },
};
</script>

<style>
.underline-hover:hover {
  text-decoration: underline;
}
</style>
