frappe.ui.form.on("Sales Order", {
    refresh: function (frm) {
        frm.add_custom_button(__('Production Plan'), function () {
            frappe.call({
                method: 'manufacturing_overview.manufacturing_overview.productionplan.makepp',
                args: {
                    sales_order: frm.docname
                },
                callback: function (r) {
                    if (r.message.status === 201) {
                        frappe.msgprint({
                            title: __('Created'),
                            indicator: 'green',
                            message: __('Production Plan {0} created.',
                                ['<a href="/app/production-plan/' + r.message.docname + '">' + r.message.docname + '</a>'])
                        });
                    } else if (r.message.status === 400) {
                        frappe.msgprint({
                            title: __('Already exists'),
                            indicator: 'yellow',
                            message: __('Production Plan {0} already exists.',
                                ['<a href="/app/production-plan/' + r.message.docname + '">' + r.message.docname + '</a>'])
                        });
                    } else {
                        console.log(r.message)
                    }
                }
            });
        })
    }
});