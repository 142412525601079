import { createApp } from "vue";

import ManufacturingOverviewDesk from "./manufacturing_overview_desk.vue";

$(document).ready(function () {
    $(".layout-main-section-wrapper").after('<div class="col-12 col-lg-4 layout-main-section-wrapper" id="manufacturing-overview-body"></div>');

    const moapp = createApp(ManufacturingOverviewDesk)

    moapp.mount("#manufacturing-overview-body");
});

