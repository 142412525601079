frappe.ui.form.on("Production Plan", {
    refresh: function (frm) {
        frm.add_custom_button(__('Fix WO/PO'), function () {
            frappe.call({
                method: 'manufacturing_overview.manufacturing_overview.productionplan.fixfinisheditemwopo',
                args: {
                    production_plan: frm.docname
                },
                callback: function (r) {
                    if (r.message.status === 201) {
                        frappe.msgprint({
                            title: __('Created'),
                            indicator: 'green',
                            message: __('Purchase Order {0} created.',
                                ['<a href="/app/purchase-order/' + r.message.docname + '">' + r.message.docname + '</a>'])
                        });
                    } else if (r.message.status === 400) {
                        frappe.msgprint({
                            title: __('Already exists'),
                            indicator: 'yellow',
                            message: "Oooopsie"
                        });
                    } else {
                        console.log(r.message)
                    }
                }
            });
        })
    }
});